import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import * as styles from './service-cards-grid.module.css'
import ServiceCard from '../serviceCard'

const ServiceCardsGrid = () => {
  const data = useStaticQuery(graphql`
    {
      services: allContentfulService {
        nodes {
          slug
          title
          excerpt
          icon {
            svg {
              content
            }
          }
        }
      }
      servicePage: contentfulPage(name: { eq: "page.service" }) {
        servicePageSlug: slug
      }
    }
  `)
  const services = data.services.nodes
  const { servicePageSlug } = data.servicePage

  return (
    <div className={styles.serviceCardsWrapper}>
      {services.map(service => {
        return (
          <ServiceCard
            key={service.title}
            title={service.title}
            excerpt={service.excerpt}
            image={service.icon.svg.content}
            slug={servicePageSlug.replace(`{{slug}}`, service.slug)}
          />
        )
      })}
    </div>
  )
}

export default ServiceCardsGrid
