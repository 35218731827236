import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../components/pageHeader'
import ServiceCardsGrid from '../components/serviceCardsGrid'
import Callout from '../components/callout'
import Section from '../components/section'
import { getTextCopy, getHtmlCopy } from '../helpers'

const Services = ({ data }) => {
  const { seoTitle, seoDescription, seoImage, pageSlug, content, callout } = data.servicesPage

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        imageUrl={seoImage.file.url}
        urlPath={pageSlug}
      />
      <Layout callout={ callout && <Callout data={callout} /> }>
        <Section>
          <PageHeader
            header={getTextCopy(content, 'page.services.header')}
            subheader={getHtmlCopy(content, 'page.services.subheader')}
          />
          <ServiceCardsGrid/>
        </Section>
      </Layout>
    </>
  )
}

export default Services

export const query = graphql`{
  servicesPage: contentfulPage(name: {eq: "page.services"}) {
    ...pageFields
  }
}`