import React, { Component } from 'react'
import LearnMoreButton from '../learnMoreButton'
import InlineSvg from '../inlineSvg'
import * as styles from './service-card.module.css'
import Card from '../card'

class ServiceCard extends Component {
  render() {
    const { title, excerpt, image, slug } = this.props
    return (
      <Card className={styles.serviceCard} linkTo={slug} rippleEffect>
        <div className={styles.serviceCardContent}>
          <InlineSvg cssClass={styles.image} content={image} />
          <div className={styles.info}>
            <h4 className={styles.title}>{title}</h4>
            <p className={styles.excerpt}>{excerpt}</p>
          </div>
          <LearnMoreButton className={styles.learnMoreButton} />
        </div>
      </Card>
    )
  }
}

export default ServiceCard
